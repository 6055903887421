// External Dependencies
import {
  FC, useCallback, useState,
} from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Collapse from '@mui/material/Collapse';
import DescriptionIcon from '@mui/icons-material/Description';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

// Internal Dependencies
import { EnhancedAlert } from 'components/shared/index';

// Local Typings
interface Props {
  disabled?: boolean;
  helperText?: string;
  marginBottom?: number;
  marginTop?: number;
  maxFiles?: number;
  multiple?: boolean;
  onDrop: ((acceptedFiles: File[]) => void) | null;
  onFileDialogCancel?: () => void;
  onFileDialogOpen?: () => void;
  preventFileDialogOpen?: boolean;
  rejectedDropErrorMessage: string;
  showAcceptedFiles?: boolean;
}

interface StyledSectionProps {
  $disabled?: boolean;
  $isDragActive: boolean;
  marginBottom: number;
  marginTop: number;
}

// Local Variables
const StyledSection = styled.section<StyledSectionProps>(({
  $disabled,
  $isDragActive,
  marginBottom,
  marginTop,
  theme,
}) => ({
  '.icon': {
    height: 144,
    width: 144,
  },
  // eslint-disable-next-line no-nested-ternary
  backgroundColor: $isDragActive
    ? theme.palette.dropzoneHover
    : $disabled
      ? theme.palette.grey[200]
      : 'inherit',
  border: `3px dashed ${$isDragActive
    ? theme.palette.dropzoneBorder
    : theme.palette.divider}`,
  borderRadius: '12px',
  marginBottom: theme.spacing(marginBottom),
  marginTop: theme.spacing(marginTop),
  textAlign: 'center',
}));

// Component Definition
const UploadDropzone: FC<Props> = ({
  disabled,
  helperText,
  marginBottom = 0,
  marginTop = 3,
  maxFiles = 1,
  multiple = false,
  onDrop,
  onFileDialogCancel,
  onFileDialogOpen,
  preventFileDialogOpen = false,
  rejectedDropErrorMessage,
  showAcceptedFiles = false,
}) => {
  const theme = useTheme();

  const [hasError, setHasError] = useState(false);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        onDrop(acceptedFiles);
      }
    },
    [onDrop],
  );

  const {
    acceptedFiles,
    getInputProps,
    getRootProps,
    isDragActive,
  } = useDropzone({
    maxFiles,
    multiple,
    noClick: preventFileDialogOpen,
    noDrag: preventFileDialogOpen,
    noKeyboard: preventFileDialogOpen,
    onDrop: handleDrop,
    onDropAccepted: () => setHasError(false),
    onDropRejected: (err, arg) => {
      console.log('rej err : ', err);
      console.log('rej arg : ', arg);

      setHasError(true);
    },
    onFileDialogCancel,
    onFileDialogOpen,
  });

  const rootProps = getRootProps({
    isDragActive,
    onChange: () => { },
    onClick: () => { },
    tabIndex: -1,
  });

  const files = acceptedFiles.map((file: FileWithPath) => (
    <ListItem key={file.path}>
      <ListItemIcon>
        <DescriptionIcon />
      </ListItemIcon>

      <ListItemText primary={file.path} />
    </ListItem>
  ));

  const hasAcceptedFiles = Boolean(files.length);

  return (
    <>
      <StyledSection
        {...rootProps}
        $disabled={disabled}
        $isDragActive={isDragActive}
        marginBottom={marginBottom}
        marginTop={marginTop}
      >
        <input
          {...getInputProps({
            accept: '*',
          })}
        />

        <CloudUploadIcon
          className="icon"
          htmlColor={theme.palette.divider}
        />

        <Typography
          color={disabled ? theme.palette.grey[500] : theme.palette.grey[600]}
          fontSize={18}
          fontWeight={400}
          gutterBottom
        >
          {isDragActive ? 'Drop your file here' : 'Drop file or click to upload'}
        </Typography>

        {helperText && (
          <Typography
            color="textSecondary"
            fontSize="small"
            gutterBottom
          >
            {helperText}
          </Typography>
        )}
      </StyledSection>

      <Collapse in={showAcceptedFiles && hasAcceptedFiles}>
        <Typography
          color="textSecondary"
          fontSize="small"
          gutterBottom
          sx={{
            marginTop: 4,
          }}
        >
          Accepted File{files.length > 1 && 's'}:
        </Typography>

        <List>
          {files}
        </List>
      </Collapse>

      <Collapse in={hasError}>
        <EnhancedAlert
          severity="error"
          sx={{ marginTop: 1 }}
        >
          {rejectedDropErrorMessage}
        </EnhancedAlert>
      </Collapse>
    </>
  );
};

export default UploadDropzone;
