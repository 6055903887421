// External Dependencies
import { FC } from 'react';
import styled from 'styled-components';

// Local Typings

// Local Variables
const StyledDiv = styled.div({
  margin: '0 auto',
  width: '95vw',
});

// Component Definition
const Container: FC = ({ children }) => (
  <StyledDiv>
    {children}
  </StyledDiv>
);

export default Container;
