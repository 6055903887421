// External Dependencies
import { formatYupError } from '@presto-assistant/api_types/utils';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ApolloProvider } from '@apollo/client';
import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Provider as ReduxProvider } from 'react-redux';
import {
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { AxiosError, AxiosResponse } from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Internal Dependencies
import ErrorBoundary from 'components/shared/ErrorBoundary';
import client from 'gql/client';
import theme from 'components/shared/theme';

// Local Dependencies
import Router from './Router';
import store from './state/store';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? '');

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error) => {
        const errorData = (error as AxiosError).response?.data;

        if (errorData) {
          const formattedError = formatYupError(errorData.error);

          if (formattedError) {
            return formattedError;
          }
        }

        return error;
      },
      onSuccess: (res) => {
        const { headers } = res as AxiosResponse;

        const downloadUrlHeader = headers?.['x-download-url'];
        const redirectUrlHeader = headers?.['x-redirect-url'];

        if (downloadUrlHeader) {
          const downloadLink = document.createElement('a');
          downloadLink.download = 'download';
          downloadLink.href = downloadUrlHeader;
          downloadLink.click();
        }

        if (redirectUrlHeader) {
          window.location.href = redirectUrlHeader;
        }
      },
    },
    queries: {
      cacheTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
  },
});

// Component Definition
const App: FC = () => (
  <div className="App">
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <ReduxProvider store={store}>
          <StylesProvider
            // This ensures that MUI styles occur at the beginning of <head>
            // So styled-components will win when specificity is otherwise equal
            injectFirst
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme />

                <StyledComponentsThemeProvider theme={theme}>
                  <ErrorBoundary>
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      dateLibInstance={moment}
                    >
                      <BrowserRouter>
                        <Router />
                      </BrowserRouter>
                    </LocalizationProvider>
                  </ErrorBoundary>
                </StyledComponentsThemeProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </StylesProvider>
        </ReduxProvider>
      </ApolloProvider>
    </QueryClientProvider>
  </div>
);

export default App;
