// External Dependencies
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken, lighten } from '@mui/material/styles';
import { UserAlertFlags } from '@presto-assistant/api_types';
import { ExtendedMember } from '@presto-assistant/api_types/api/admin/members';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { displaySchoolYearString } from '@presto-assistant/api_types/utils';

// Internal Dependencies
import {
  Address,
  EnhancedCard,
  EnhancedCardContent,
  ShowPageDataDisplay,
  Subtitle,
} from 'components/shared';
import { getFullName } from 'utils';
import { getOrganizationRole } from 'utils/lib/get_organization_role';

// Local Dependencies
import MemberSearchChip from './shared/MemberSearchChip';

// Local Typings
interface MemberWithStateLabel extends Partial<ExtendedMember> {
  stateLabel?: string;
}

interface Props {
  member?: MemberWithStateLabel;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.link': {
    borderBottom: `1px solid ${theme.palette.link}`,
    boxShadow: `inset 0 -2px 0px 0px ${theme.palette.link}`,
    color: darken(theme.palette.link, 0.9),
    marginLeft: theme.spacing(1),
    padding: '4px 1px 0px',
    textDecoration: 'none',
    transition: 'all 0.15s ease-out',
  },
  '.member-search-result-address': {
    fontSize: '0.9rem',
  },
  '.member-search-result-address-subheader': {
    marginTop: theme.spacing(2),
  },
  '.member-search-result-card-header': {
    background: lighten(theme.palette.prestoPrimaryLight, 0.9),
  },
  '.member-search-result-header': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    marginBottom: 0,
  },
  '.member-search-result-membership': {
    background: lighten(theme.palette.stripeBlue['300'], 0.92),
  },
  borderRadius: 12,
  marginBottom: theme.spacing(1.5),
})) as typeof Typography;

// Component Definition
const MemberSearchResultCard = ({ member }: Props): JSX.Element | null => {
  const isFraudUser = member?.alertFlagId === UserAlertFlags.Fraud;
  const hasDobViolation = member?.alertFlagId === UserAlertFlags.DOBViolation;

  const hasMembershipData = Boolean(member?.memberships?.length);

  return (
    <Collapse in={Boolean(member)}>
      <StyledEnhancedCard>
        <EnhancedCardContent className="member-search-result-card-header">
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexWrap="wrap"
              maxWidth="50%"
            >
              <Typography
                className="member-search-result-header"
                component="h3"
                paragraph
                variant="h6"
              >
                {member?.organizationLabel}
              </Typography>

              <Link
                className="link"
                to={`/organizations/${member?.organizationId}`}
              >
                link
              </Link>
            </Box>

            <Box
              alignItems="flex-end"
              display="flex"
              flexDirection="column"
              gap={1.5}
            >
              <Typography
                className="member-search-result-header"
                component="h4"
                variant="h6"
              >
                {toTitleCase(getFullName({
                  firstName: member?.firstName || '',
                  lastName: member?.lastName || '',
                }))}
              </Typography>

              {isFraudUser && (
                <MemberSearchChip
                  color="error"
                  label="Fraud"
                  variant="outlined"
                />
              )}
              {hasDobViolation && (
                <MemberSearchChip
                  color="error"
                  fontWeight={500}
                  label="Under 13 years old"
                  variant="outlined"
                />
              )}
            </Box>
          </Box>
        </EnhancedCardContent>

        <EnhancedCardContent>
          {member?.title && (
            <ShowPageDataDisplay
              label="Title"
              value={member?.title}
            />
          )}

          {member?.deletedAt && (
            <>
              <ShowPageDataDisplay
                label="Deleted At"
                type="datetime"
                value={member.deletedAt}
              />
              <ShowPageDataDisplay
                label="Deleted Note"
                value={member.deletedNote}
              />
            </>
          )}

          <ShowPageDataDisplay
            label="Current organization"
            type="boolean"
            value={member?.isCurrentOrganization}
          />

          <ShowPageDataDisplay
            label="Is Approved"
            type="boolean"
            value={member?.isApproved}
          />

          {member?.claimCode && (
            <ShowPageDataDisplay
              label="Claim Code"
              value={member.claimCode}
            />
          )}

          <ShowPageDataDisplay
            label="Date of Birth"
            type="date"
            value={member?.dateOfBirth}
          />
          <ShowPageDataDisplay
            label="Created At"
            type="datetime"
            value={member?.createdAt}
          />
          <ShowPageDataDisplay
            label="Updated At"
            type="datetime"
            value={member?.updatedAt}
          />

          <Typography
            className="member-search-result-address-subheader"
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Address
          </Typography>

          <Address
            addressOne={member?.addressOne}
            addressTwo={member?.addressTwo}
            city={member?.city}
            className="member-search-result-address"
            phoneNumber={member?.phoneNumber}
            state={member?.stateLabel}
            zipcode={member?.zipcode}
          />

          {hasMembershipData && (
            <Box marginTop={2}>
              <Subtitle>Memberships</Subtitle>

              <Box
                display="flex"
                flexWrap="wrap"
                gap={2}
              >
                {member?.memberships?.map((membership) => {
                  const organizationRole = membership
                    ? getOrganizationRole({
                      isAdmin: membership.admin,
                      userRoleId: membership.userRoleId,
                    }) : '';

                  return (
                    <EnhancedCard
                      className="member-search-result-membership"
                      key={membership.id}
                    >
                      <EnhancedCardContent>
                        <Box
                          display="flex"
                          flexDirection="column"
                        >
                          <Typography
                            fontWeight={500}
                            gutterBottom
                            textAlign="center"
                            variant="subtitle1"
                          >
                            {displaySchoolYearString(membership.schoolYearEnding ?? 0)}
                          </Typography>

                          {organizationRole && (
                            <MemberSearchChip
                              color="primary"
                              label={organizationRole}
                              size="small"
                              variant="outlined"
                            />
                          )}

                          <ShowPageDataDisplay
                            label="Is Director"
                            type="boolean"
                            value={membership.admin}
                          />

                          <ShowPageDataDisplay
                            label="Active"
                            type="boolean"
                            value={membership.isActive}
                          />

                          <ShowPageDataDisplay
                            label="Grade"
                            value={membership.grade}
                          />

                          <ShowPageDataDisplay
                            label="Received Welcome Email"
                            type="boolean"
                            value={Boolean(membership.receivedWelcomeEmailAt)}
                          />
                        </Box>
                      </EnhancedCardContent>
                    </EnhancedCard>
                  );
                })}
              </Box>
            </Box>
          )}
        </EnhancedCardContent>
      </StyledEnhancedCard>
    </Collapse>
  );
};

export default MemberSearchResultCard;
