// External Dependencies
import {
  ApolloError,
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { DocumentNode } from 'graphql';

// Internal Dependencies
import { parseSearch } from 'utils';
import client from 'gql/client';

// Local Typings
export interface MutationOptions<T, S> extends MutationHookOptions<T, S> {
  clearCachePredicates?: string[]
}
export type MutationEnhancedOptions<TData = any, TVariables = any>
  = MutationHookOptions<TData, TVariables> & { clearCachePredicates?: string[] };

// Local Variables
const ANNOYING_GRAPHQL_ERROR_HEADER = 'GraphQL error: ';
const removeAnnoyingHeader = (error?: ApolloError): ApolloError | undefined =>
  error && {
    ...error,
    message: error.message.replace(ANNOYING_GRAPHQL_ERROR_HEADER, ''),
  };

// Exports
export function useQueryEnhanced<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables> | undefined,
): QueryResult<TData, TVariables> {
  const data = useQuery<TData, TVariables>(query, options);
  data.error = removeAnnoyingHeader(data.error);

  return data;
}

export function useMutationEnhanced<
  TData = any,
  TVariables = OperationVariables
>(
  mutation: DocumentNode,
  {
    clearCachePredicates,
    ...options
  }: MutationEnhancedOptions<TData, TVariables> = {},
): MutationTuple<TData, TVariables> {
  const [
    enhancedMutation,
    {
      error,
      ...remaining
    },
  ] = useMutation(
    mutation,
    options && {
      ...options,
      onCompleted: (data) => {
        if (clearCachePredicates) {
          const rootQuery = (client.cache as any).data.data.ROOT_QUERY;
          const rootQueryKeys = Object.keys(rootQuery);

          rootQueryKeys.forEach((key) => {
            if (clearCachePredicates.some((predicate) => key.startsWith(predicate))) {
              client.cache.evict({ fieldName: key, id: 'ROOT_QUERY' });
            }
          });

          client.cache.gc();
        }

        if (options.onCompleted) {
          options.onCompleted(data);
        }
      },
      onError: (e: ApolloError) => {
        if (clearCachePredicates) {
          const rootQuery = (client.cache as any).data.data.ROOT_QUERY;
          const rootQueryKeys = Object.keys(rootQuery);

          rootQueryKeys.forEach((key) => {
            if (clearCachePredicates.some((predicate) => key.startsWith(predicate))) {
              client.cache.evict({ fieldName: key, id: 'ROOT_QUERY' });
            }
          });

          client.cache.gc();
        }

        const cleanError = removeAnnoyingHeader(e) as ApolloError;

        if (options.onError) {
          options.onError(cleanError);
        }
      },
    },
  );
  const errorEnhanced = removeAnnoyingHeader(error);

  return [
    enhancedMutation,
    {
      ...remaining,
      error: errorEnhanced,
    },
  ];
}

export function useLazyQueryEnhanced<
  TData = any,
  TVariables = Record<string, any>
>(
  query: DocumentNode,
  // omitting client because it is incompatible 😞
  options: Omit<LazyQueryHookOptions<TData, TVariables>, 'client'> = {},
): QueryTuple<TData, TVariables> {
  const [
    enhancedQuery,
    {
      error,
      ...remaining
    },
  ] = useLazyQuery(
    query,
    {
      ...options,
      onCompleted: (args: any) => {
        if (options.onCompleted) {
          options.onCompleted(args);
        }
      },
      onError: (e: ApolloError) => {
        const cleanError = removeAnnoyingHeader(e) as ApolloError;

        if (options.onError) {
          options.onError(cleanError);
        }
      },
    },
  );
  const errorEnhanced = removeAnnoyingHeader(error);

  return [
    enhancedQuery,
    {
      ...remaining,
      error: errorEnhanced,
    } as any, // incompatible client issue. Should be able to be inferred otherwise
  ];
}

export const getIndexQueryParams = (searchString: string) => {
  const search = parseSearch(searchString);

  const queryParams: GQL.IIndexQueryParams = {
    limit: search.limit ?? 10,
    orderBy: search.orderBy ? [{
      asc: search.asc ?? true,
      column: search.orderBy,
    }] : [],
    page: search.page ?? 1,
    q: `${search.q ?? ''}`,
  };

  return queryParams;
};
