// External Dependencies
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import {
  DeleteDistrictRequest,
} from '@presto-assistant/api_types/api/admin/district';

// Internal Dependencies
import { prestoAdminApiClient } from 'utils/api/apiClient';
import { useTanstackMutation } from 'utils/api/index';
import { evictApolloCache } from 'gql/client';

export const useDeleteDistrict = () => {
  const mutationFn = useCallback((args: {
    id: string;
    payload: DeleteDistrictRequest['body'];
  }) => {
    return prestoAdminApiClient.v1.district.delete({
      id: args.id,
      payload: args.payload,
    });
  }, []);

  // TODO: clear district index cache once we move it to react-query
  // for now, we'll just do a hard redirect
  return useMutation({
    mutationFn,
    onSuccess: () => {
      window.location.href = '/districts';
    },
  });
};

export const useCreateTaxExemptFormPresignedUrl = () => {
  const mutationFn = useCallback((payload) => {
    return prestoAdminApiClient.v1.district.createTaxExemptFormPresignedUrl(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: (): void => {
      evictApolloCache('districtById');
    },
  });
};

export const useAddTaxExemptFormS3Key = () => {
  const navigate = useNavigate();

  const mutationFn = useCallback((payload) => {
    return prestoAdminApiClient.v1.district.addTaxExemptFormS3Key(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictApolloCache('districtById');
      evictApolloCache('districts');
      navigate(-1);
    },
  });
};

export const useDownloadTaxExemptForm = () => {
  return useMutation({
    mutationFn: (districtId: string) =>
      prestoAdminApiClient.v1.district.downloadTaxExemptForm({ districtId }),
  });
};

export const useGetLicenses = (districtId: string) => {
  return useQuery({
    queryFn: () =>
      prestoAdminApiClient.v1.district.getLicenses({ params: { districtId } }),
    queryKey: ['licenses', districtId],
  });
};
