// External Dependencies
import { FC, useState } from 'react';
import { Formik, Form } from 'formik';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import EnhancedCard from 'components/shared/EnhancedCard';
import SaveButton from 'components/shared/SaveButton';
import {
  Flex,
  BackToButton,
  Subtitle,
  CustomInput,
} from 'components/shared';
import { useGetAppSettingById, useUpdateAppSetting } from 'hooks/api/appSettings';

// Component Definition
const UpdateAppSettings: FC = () => {
  const [isSaved, setIsSaved] = useState(false);
  const {
    error,
    isLoading: isUpdating,
    mutate: updateAppSetting,
  } = useUpdateAppSetting();

  const handleSubmit = (values: any) => {
    updateAppSetting({ id: '1', value: values.appSettingValue });
    setIsSaved(true);

    setTimeout(() => {
      setIsSaved(false);
    }, 5000);
  };

  const { data: appSettingResponse } = useGetAppSettingById('1');

  return (
    <>
      <BackToButton
        context="Admin Actions"
        navigateTo="/admin_actions"
      />
      <Subtitle hasGutterTop>Update App Settings</Subtitle>

      <Formik
        enableReinitialize
        initialValues={{ appSettingValue: appSettingResponse?.data?.data?.value || '' }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <EnhancedCard>
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  {isSaved && (
                    <Typography
                      align="center"
                      color="primary"
                      component="h2"
                    >
                      Saved!
                    </Typography>
                  )}

                  <CustomInput
                    label="Minimum Web App Version"
                    name="appSettingValue"
                    type="text"
                  />
                </Box>
              </CardContent>

              {error && (
                <Typography color="error">
                  Error: {error}
                </Typography>
              )}

              <CardActions sx={{ height: '4rem', padding: '8px' }}>
                <Flex
                  justifyContent="flex-end"
                  width="100%"
                >
                  <SaveButton
                    color="primary"
                    isSaving={isUpdating}
                  >
                    Update Setting
                  </SaveButton>
                </Flex>
              </CardActions>
            </EnhancedCard>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateAppSettings;
