// External Dependencies
import {
  useCallback, useEffect, useState,
} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { useDidMount } from 'hooks/useDidMount';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';

// Local Dependencies
import { getMyProfile } from '../utils/api';
import { getToken } from '../utils/cookies';
import { setUser } from '../state/actions';
import Container from './shared/Container';
import Footer from './shared/Footer';
import NavBar from './NavBar';
import SimpleContainer from './SimpleContainer';

// Local Variables
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
  },
}));

// Component Definition
const BaseLayout = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const didMount = useDidMount();
  const [token, setToken] = useState('');

  const user = useGetCurrentUser();

  // Set the token in state when the component mounts
  useEffect(() => {
    setToken(getToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setToken(didMount && !user ? '' : getToken());
  }, [didMount, user]);

  const getUser = useCallback(async () => {
    const userDataResponse = await getMyProfile();
    const userFromResponse: any = userDataResponse?.data;

    if (userFromResponse?.error) {
      navigate('/logout');
    } else if (userFromResponse) {
      dispatch(setUser(userFromResponse[0]));
    }
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      getUser();
    }
  }, [token, getUser]);

  return (
    <div className={classes.root}>
      {token && <NavBar hasToken={Boolean(token)} />}

      <SimpleContainer>
        <Container>
          {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes nested in this layout in the <Router>. */}
          <Outlet />
        </Container>
      </SimpleContainer>
      <Footer />
    </div>
  );
};

export default BaseLayout;
