// External Dependencies
import {
  FC, useCallback, useRef, useState,
} from 'react';
import { alpha, lighten } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import MenuItem from '@mui/material/MenuItem';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import { AdminUser } from 'state/reducers/user_reducer';
import { getSearchWithRedirectUrl } from 'utils/lib/redirect_user';
import { isMobileScreenSize } from 'state/selectors';

// Local Dependencies
import {
  EnhancedMenuItem,
  PrestoLogoWhiteSvg,
} from './shared';

// Local Typings
interface Props {
  user: AdminUser | unknown;
}

// Local Variables
const drawerWidth = 224;

// Local Variables
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    minWidth: drawerWidth,
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  listText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem',
    },
  },
  logoContainer: {
    backgroundColor: theme.palette.grey.A400,
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(3)} 0`,
  },
  menuButton: {
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    marginRight: theme.spacing(1),
  },
  navBar: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5),
    },
    backgroundColor: theme.palette.prestoPrimaryDark,
    padding: 10,
  },
  selfNameMenuItem: {
    backgroundColor: theme.palette.grey['200'],
    cursor: 'default',
    marginBottom: theme.spacing(1),
  },
  textPrimary: {
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
    color: theme.palette.text.primary,
  },
  title: {
    color: theme.palette.common.white,
    fontFamily: 'BioRhyme',
    fontWeight: 800,
    marginLeft: 12,
  },
  userInfo: {
    margin: '0 16px',
  },
  userNameContainer: {
    '&:hover, &:focus': {
      background: lighten(theme.palette.prestoPrimaryDark, 0.1),
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    alignItems: 'center',
    border: `1px solid ${alpha(theme.palette.common.white, 0.8)}`,
    display: 'flex',
    marginRight: theme.spacing(1),
    padding: '5px 8px 5px 10px',
  },
  userNameText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
    color: theme.palette.common.white,
    marginLeft: 8,
    marginRight: 6,
  },
}));

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  height: 20,
  paddingLeft: theme.spacing(0.5),
  width: 20,
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(1)} 0`,
}));

const anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
const transformOrigin = { horizontal: 'right', vertical: 'top' };

// Component Definition
const AccountSubMenu: FC<Props> = ({ user }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    palette: {
      common,
    },
  } = useTheme();

  const isMobileScreen = useSelector(isMobileScreenSize);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClickAccountMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(async () => {
    const queryString = getSearchWithRedirectUrl();

    await handleClose();
    navigate(`/logout?${queryString}`);
  }, [handleClose, navigate]);

  if (!user) {
    return null;
  }

  return (
    <>
      <Button
        aria-controls={isMenuOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        className={classes.userNameContainer}
        onClick={handleClickAccountMenu}
        ref={buttonRef}
        variant="outlined"
      >
        <Box
          height={24}
          mr={0.5}
          pt={0.5}
          width={24}
        >
          <PrestoLogoWhiteSvg />
        </Box>
        <Typography
          className={classes.userNameText}
          color="primary"
          variant="button"
        >
          {(user as AdminUser).firstName}
        </Typography>
        <StyledKeyboardArrowDownIcon htmlColor={common.white} />
      </Button>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin as PopoverOrigin}
        id="account-menu"
        onClose={handleClose}
        open={isMenuOpen}
        role="menu"
        transformOrigin={transformOrigin as PopoverOrigin}
      >
        <MenuItem
          className={classes.selfNameMenuItem}
          dense={isMobileScreen}
        >
          <ListItemText
            classes={{ primary: classes.listText }}
            primary={`${(user as AdminUser).firstName} ${(user as AdminUser).lastName}`}
          />
        </MenuItem>

        <StyledDivider />

        <EnhancedMenuItem
          dense={isMobileScreen}
          icon={LogoutIcon}
          onClick={handleLogout}
          text="Logout"
        />
      </Popover>
    </>
  );
};

export default AccountSubMenu;
