// External Dependencies
import { FC, ReactElement } from 'react';
import styled from 'styled-components';

// Local Typings
interface Props {
  children: ReactElement | ReactElement[];
}

// Local Variables
const StyledDiv = styled.div(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
  },
  backgroundColor: theme.palette.grey[50],
  display: 'flex',
  flexFlow: 'column',
  flexGrow: 1,
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
}));

const StyledMain = styled.main(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(2),
  },
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  paddingTop: theme.spacing(4),
}));

// Component Definition
const SimpleContainer: FC<Props> = ({ children }) => (
  <StyledDiv>
    <StyledMain>
      {children}
    </StyledMain>
  </StyledDiv>
);

export default SimpleContainer;
