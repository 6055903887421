// External Dependencies
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import apolloLogger from 'apollo-link-logger';

// Internal Dependencies
import { getToken, setTokenInStorage } from 'utils/cookies';

// Local Dependencies
// We cannot destructure package.json import
import packageJson from '../../package.json';

const setTokenLink = new ApolloLink((operation, forward) =>
  forward(operation).map((res: any) => {
    const context = operation.getContext();
    const { response } = context;
    const tokenValue = response.headers?.get('x-access-token');
    if (tokenValue) {
      setTokenInStorage(tokenValue);
    }
    return res;
  }));

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => console.log(message));
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookies if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-access-token': token || '',
      'x-app-name': packageJson.name,
      'x-app-version': packageJson.version,
    },
  };
});

setTokenLink.concat(httpLink as any);

const link = process.env.NODE_ENV === 'development'
  ? from([authLink, apolloLogger, errorLink, setTokenLink, httpLink])
  : from([authLink, errorLink, setTokenLink, httpLink as any]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development',
  link,
});

export const evictApolloCache = (fieldName: string) => {
  client.cache.evict({ fieldName, id: 'ROOT_QUERY' });
};

export default client;
