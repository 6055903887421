// External Dependencies
import { FC, ReactNode } from 'react';
import { convertCentsToDollars } from '@presto-assistant/api_types/utils';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import linkifyHtml from 'linkify-html';
import momemt from 'moment';
import styled, { useTheme } from 'styled-components';
import sanitizeHtml from 'sanitize-html';

// Internal Dependencies
import {
  displayPriceStringFromDollarAmount,
} from 'utils/lib/currency';

// Local Typings
interface Props {
  label: string;
  type?: 'boolean' | 'currency' | 'date' | 'datetime' | 'html' | 'number' | 'text';
  value?: boolean | string | number | ReactNode | null;
}

// Local Variables
const StyledWrapper = styled.div(({ theme }) => ({
  margin: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} 0`,
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.7rem',
  },
  fontSize: '0.8rem',
}));

const StyledValue = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9rem',
  },
  fontSize: '1rem',
})) as typeof Typography;

const StyledEmDash = styled.span(({ theme }) => ({
  color: theme.palette.grey[700],
}));

// Component Definition
const ShowPageDataDisplay: FC<Props> = ({
  label,
  type = 'text',
  value,
}: Props) => {
  const theme = useTheme();

  const emDashElement = <StyledEmDash>—</StyledEmDash>;

  let displayValue;
  if (type === 'text' || type === 'number' || typeof value === 'object') {
    displayValue = value;
  } else if (type === 'currency') {
    displayValue = value
      ? displayPriceStringFromDollarAmount(convertCentsToDollars(Number(value)))
      : '';
  } else if (value && typeof value !== 'boolean' && type === 'date') {
    displayValue = momemt(value).format('M-D-YYYY');
  } else if (value && typeof value !== 'boolean' && type === 'datetime') {
    displayValue = momemt(value).format('MM/DD/YYYY hh:mm a');
  } else if (value && typeof value === 'boolean' && type === 'boolean') {
    displayValue = <CheckIcon htmlColor={theme.palette.success.dark} />;
  } else if (!value && typeof value === 'boolean' && type === 'boolean') {
    displayValue = 'No';
  }

  const component = typeof value === 'object' ? 'div' : 'p';

  return (
    <StyledWrapper>
      <Box display="flex">
        <StyledLabel color="textSecondary">
          {label}
        </StyledLabel>
      </Box>

      {type === 'html' && value ? (
        <StyledValue
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(linkifyHtml(value as string)),
          }}
        />
      ) : (
        <StyledValue component={component}>
          {displayValue || emDashElement}
        </StyledValue>
      )}
    </StyledWrapper>
  );
};

export default ShowPageDataDisplay;
