// External Dependencies
import { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

// Local Variables
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

// Component Definition
const FullWidthDiv: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

export default FullWidthDiv;
