// External Dependencies
import { FC } from 'react';
import {
  Box, Card, CardContent, Typography,
} from '@mui/material';

// Internal Dependencies
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils/lib/currency';
import { useGetDistrictTotals } from 'hooks/useGetDistrictTotals';

// Local Dependencies
import DailyActiveUsers from './DailyActiveUsers';
import AnnualValue from './AnnualValue';

// Component Definition
const Dashboard: FC = () => {
  const {
    totalActiveLicenses,
    totalDistrictValueInCents,
  } = useGetDistrictTotals();

  const totalRevenuePriceString = displayPriceStringFromDollarAmount(
    convertCentsToDollars(totalDistrictValueInCents),
  );

  return (
    <>
      <DailyActiveUsers />

      <AnnualValue />

      <Box
        display="flex"
        gap={4}
        marginTop={4}
      >
        <Card>
          <CardContent>
            <Typography component="h5">
              # of Active Licenses
            </Typography>

            <Typography variant="h2">
              {totalActiveLicenses}
            </Typography>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography component="h5">
              Total Annual Value
            </Typography>

            <Typography variant="h2">
              {totalRevenuePriceString}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>

  );
};

export default Dashboard;
