// External Dependencies
import {
  ReactElement, useMemo, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { useSelector } from 'react-redux';
import AlarmIcon from '@mui/icons-material/Alarm';
import AppBar from '@mui/material/AppBar';
import AppsIcon from '@mui/icons-material/Apps';
import Box from '@mui/material/Box';
import BusinessIcon from '@mui/icons-material/Business';
import DnsIcon from '@mui/icons-material/Dns';
import Drawer from '@mui/material/Drawer';
import FeedbackIcon from '@mui/icons-material/FeedbackOutlined';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import PublishIcon from '@mui/icons-material/Publish';
import ScienceIcon from '@mui/icons-material/Science';
import StoreIcon from 'mdi-material-ui/Store';
import ToolsIcon from 'mdi-material-ui/Tools';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

// Internal Dependencies
import {
  isMobileScreenSize,
  selectIsPrestoOwner,
} from 'state/selectors';
import {
  Flex,
  NavMenuItem,
  PrestoLogoGradientSvg,
} from 'components/shared';
import { tableQueryParams } from 'state/table/selectors';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';

// Local Dependencies
import AccountSubMenu from './AccountSubMenu';

// Local Typings
interface Props {
  hasToken: boolean;
}

interface Link {
  icon: (props: SvgIconProps) => ReactElement<SvgIconProps>;
  label: string;
  path: string;
}

// Local Variables
const drawerWidth = 224;

// Local Variables
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    minWidth: drawerWidth,
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  logoContainer: {
    backgroundColor: theme.palette.grey['800'],
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3, 0),
  },
  menuButton: {
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    marginRight: theme.spacing(1),
  },
  navBar: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5),
    },
    backgroundColor: theme.palette.prestoPrimaryDark,
    padding: 10,
  },
  textPrimary: {
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
    color: theme.palette.text.primary,
  },
  title: {
    color: theme.palette.common.white,
    fontFamily: 'BioRhyme',
    fontWeight: 800,
    marginLeft: 12,
  },
  userInfo: {
    margin: '0 16px',
  },
}));

const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: '0.9em',
    width: '0.9em',
  },
}));

const StyledAppTitle = styled.span(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1.1rem',
  },
}));

// Component Definition
const NavBar = ({ hasToken }:Props): JSX.Element | null => {
  const classes = useStyles();

  const isMobileScreen = useSelector(isMobileScreenSize);

  const user = useGetCurrentUser();
  const isPrestoOwner = useSelector(selectIsPrestoOwner);

  const districtsParams = useSelector(tableQueryParams('districts'));
  const feedbackParams = useSelector(tableQueryParams('feedback'));
  const importsParams = useSelector(tableQueryParams('imports'));

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const LINKS: Link[] = useMemo(() => ([
    {
      icon: SearchIcon,
      label: 'Search',
      path: '/search',
    },
    {
      icon: ToolsIcon,
      label: 'Admin Actions',
      path: '/admin_actions',
    },
    {
      icon: AlarmIcon,
      label: 'Scheduled Cron Jobs',
      path: '/scheduled_cron_jobs',
    },
    {
      icon: BusinessIcon,
      label: 'Districts',
      path: `/districts${districtsParams}`,
    },
    {
      icon: AppsIcon,
      label: 'Organizations',
      path: '/organizations',
    },
    {
      icon: PublishIcon,
      label: 'Imports',
      path: `/imports${importsParams}`,
    },
    ...isPrestoOwner ? [
      {
        icon: DnsIcon,
        label: 'Server Settings',
        path: '/server_settings',
      },
      {
        icon: ScienceIcon,
        label: 'API Playground',
        path: '/api_playground',
      },
    ] : [],
    {
      icon: FeedbackIcon,
      label: 'User Feedback',
      path: `/feedback${feedbackParams}`,
    },
    {
      icon: StoreIcon,
      label: 'Vendors',
      path: '/vendors',
    },
  ]), [districtsParams, feedbackParams, isPrestoOwner]);

  if (!user) {
    return null;
  }

  return (
    <div>
      {hasToken && Boolean(user) && (
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={toggleDrawer}
          open={isDrawerOpen}
        >
          <div className={classes.logoContainer}>
            <PrestoLogoGradientSvg />
          </div>

          <div
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
            role="button"
            tabIndex={0}
          >
            <List dense={isMobileScreen}>
              {LINKS.map((link) => (
                <NavMenuItem
                  icon={link.icon}
                  key={link.label}
                  text={link.label}
                  to={link.path}
                />
              ))}
            </List>
          </div>
        </Drawer>
      )}

      <AppBar
        className={classes.navBar}
        position="static"
      >
        <Flex justifyContent="space-between">
          <Box
            alignItems="center"
            display="flex"
          >
            {hasToken && Boolean(user) && (
              <IconButton
                aria-label="Menu"
                className={classes.menuButton}
                color="inherit"
                onClick={toggleDrawer}
                size="large"
              >
                <StyledMenuIcon />
              </IconButton>
            )}
            <Typography
              className={classes.title}
              color="primary"
              variant="h5"
            >
              <Link
                className={classes.link}
                to="/dashboard"
              >
                <StyledAppTitle>
                  Sparkle{' '}
                  <span
                    aria-label="Sparkle emoji"
                    role="img"
                  >
                    ✨
                  </span>
                </StyledAppTitle>
              </Link>
            </Typography>
          </Box>

          <AccountSubMenu user={user} />
        </Flex>
      </AppBar>
    </div>
  );
};

export default NavBar;
